@import 'import';
.PackageCardChooseVoyage,
.ZeroResultsDefaultVoyages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: $container-sm-width) {
    justify-content: center;
  }
}

.PackageCardChooseVoyage {
  margin-top: 30px;

  &.isSinglePackage {
    @media (max-width: $container-lg1-width) {
      flex-direction: row;
    }
  }

  &.isAKWrapper {
    margin-top: 0;
  }
}
